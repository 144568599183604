<template>
  <a v-bind="this.$attrs" @click="copy_from_ticket">
     <slot></slot>
  </a>
</template>


<script>
export default {
  inheritAttrs: false,
  props: {
    ticket_type: {
      type: String,
      require: true,
    }
  },
  computed: {},

  methods: {
    copy_from_ticket() {
      let attributes = [
          "quantity",
          "measure_unit_id",
          "shipment_period_start",
          "shipment_period_end",
          "packing_id",
          'fixed_price',
          'futures_date',
          'premium_price',
          'final_price'
      ]

      attributes.forEach((attribute) => {
        let ticket_value = this.$store.getters.getValue(this.ticketName(attribute))
        let shipment_value = this.$store.getters.getValue(this.shipmentName(attribute))
        if (ticket_value !== shipment_value) {
          this.$store.dispatch('update', {
                value: ticket_value,
                name: this.shipmentName(attribute)
              }
          )
        }
      })
    },
    ticketName:function(attribute) {
      let ticket_id = this.$store.getters.getValue(this.shipmentName(`${this.$props.ticket_type}_id`))

      // the store has a special structure for shipments, when creating a new shipment
      // => shipment[sale_attributes] will always contain data of a new sale
      // => shipment[sale_attributes_2] will contain the data of an existing sale (that was selected by the user)
      // => idem for puchase_attributes
      // => see update_shipment_ticket_attributes plugin for more details

      if (ticket_id) {
        return `trading_shipment[${this.$props.ticket_type}_attributes_2][${attribute}]`
      } else {
        return `trading_shipment[${this.$props.ticket_type}_attributes][${attribute}]`
      }
    },
    shipmentName:function(attribute) {
      return `trading_shipment[${attribute}]`
    },
  },
};
</script>

import { Controller } from "stimulus";
export default class extends Controller {
    static targets = ["persist"]; // Target for elements to persist by ID
    connect() {
        this.registerEventListeners();
    }
    disconnect() {
        this.unregisterEventListeners();
    }

    registerEventListeners() {
        this.element.addEventListener("turbo:before-frame-render", this.rememberFocus.bind(this));
        this.element.addEventListener("turbo:frame-render", this.restoreFocus.bind(this));
    }

    unregisterEventListeners() {
        this.element.removeEventListener("turbo:before-frame-render", this.rememberFocus.bind(this));
        this.element.removeEventListener("turbo:frame-render", this.restoreFocus.bind(this));
    }

    rememberFocus(event) {
        const activeElement = document.activeElement;

        if (this.shouldRememberFocus(activeElement)) {
            this.storeFocusInfo(activeElement);
        } else {
            this.focusInfo = null;
        }
    }

    shouldRememberFocus(activeElement) {
        if (!activeElement) return false;

        const closestTurboFrame = activeElement.closest("turbo-frame");
        return closestTurboFrame === this.element && activeElement.id;
    }

    storeFocusInfo(activeElement) {
        this.focusInfo = {
            id: activeElement.id,
            selectionStart: activeElement?.selectionStart ?? null,
            selectionEnd: activeElement?.selectionEnd ?? null
        };
    }
    restoreFocus(event) {
        if ( this.focusInfo ) {
            let myElement = this.element.querySelector(`#${this.focusInfo.id}`)
            if (myElement) {
                myElement.focus()
                if (this.focusInfo.selectionStart) { myElement.selectionStart = this.focusInfo.selectionStart }
                if (this.focusInfo.selectionEnd) { myElement.selectionEnd = this.focusInfo.selectionEnd }
                this.focusInfo = null
            }
        }
    }
}
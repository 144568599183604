import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  add(event) {
    this.frame = this.element.closest("turbo-frame");
    const url = new URL(this.frame.src, window.location.origin);

    //remove initial_load if present, otherwise it would be persisted and we do not want that
    //fixme: find a better way to handle the way links are preloaded from the permalinks
    url.searchParams.delete('_initial_load');

    Object.entries(event.params.params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    this.frame.src = url.toString()
  }

}

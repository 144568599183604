const plugin = (store) => {
    store.subscribeAction({
        before: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.
        },
        after: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.

            if (action.type !== 'dataReceivedFromServer' ) {
                return;
            }

            let computedValuesNames =  [
                "trading_sale[total_quantity]",
                "trading_sale[quantity_to_ship]",
                "trading_sale[total_nb_of_lots]",
                "trading_purchase[total_quantity]",
                "trading_purchase[quantity_to_ship]",
                "trading_purchase[total_nb_of_lots]",
                "trading_shipment[transit_time]",
                "trading_shipment[sale_attributes][total_quantity]",
                "trading_shipment[sale_attributes][quantity_to_ship]",
                "trading_shipment[sale_attributes][product_id]",
                "trading_shipment[purchase_attributes][total_quantity]",
                "trading_shipment[purchase_attributes][quantity_to_ship]",
                "trading_shipment[purchase_attributes][product_id]",
            ]

            computedValuesNames.forEach(function(name) {
                let currentValue = store.getters.getValue(name)
                let suggestedValue = store.getters.getSuggestedValues(name)

                if (suggestedValue !== currentValue) {
                    store.commit('setValue', {
                            value: suggestedValue,
                            name: name
                        }
                    )
                }
            })
        },
    });


    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    });
};

export default plugin;

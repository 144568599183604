// TODO: extract to a shared lib and add tests to cover all the case

import {Controller} from "stimulus";
import { navigator } from '@hotwired/turbo'
import LZString from "lz-string"; // Import lz-string for compression

export default class extends Controller {
    static values = { defaultSrc: String, srcFromPermalink: Boolean };

    connect() {
        this.frame = this.element.querySelector("turbo-frame");
        this.loadCompressedSrcFromMainUrlOrDefault();


        // Listen for turbo:frame-load events to capture URL changes in the nested frame
        this.frame.addEventListener("turbo:frame-load", () => {
            this.syncNestedFrameSrcToMainUrl();
        });
    }

    loadCompressedSrcFromMainUrlOrDefault() {
        // Extract the compressed URL from the main page's URL parameters
        const url = new URL(window.location);
        const compressedSrc = url.searchParams.get(`${this.frame.id}_src`);

        if (this.srcFromPermalinkValue) {
            if (compressedSrc) {
                this.frame.src = this.decompress(compressedSrc);
            } else {
                this.frame.src = this.defaultSrcValue;
            }

            this.srcFromPermalinkValue = false
        } else {
            this.frame.src = this.defaultSrcValue;
        }
    }

    syncNestedFrameSrcToMainUrl() {
        // Compress the current src and store it in the main URL
        const currentSrc = this.frame.src;
        const compressedSrc = this.compress(currentSrc);

        const url = new URL(window.location);

        url.searchParams.set(`${this.frame.id}_src`, compressedSrc);
        navigator.history.replace(url);
    }
    compress(str) {
        // Compress and encode to URL-safe Base64
        return LZString.compressToEncodedURIComponent(str);
    }

    decompress(str) {
        // Decompress from URL-safe Base64
        return LZString.decompressFromEncodedURIComponent(str);
    }
}

<template>
  <div class="form-validation">

    <div :class="this.$props.classes" v-if="icon">
      <input v-bind="this.$attrs" @click="submitting" :disabled="disabled" />

      <span v-if="icon" :class="`submit-append-icon submit-${icon}`"></span>
    </div>

    <input v-if="!icon" v-bind="this.$attrs" @click="submitting" :disabled="disabled" :class="this.$props.classes" />

    <div class="form-validation__global-error"
         v-if="displayValidationWarning"
         @click="displayValidationMessages = !displayValidationMessages"
    >
      <svg width="3" height="13" viewBox="0 0 3 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.816 11.72C2.816 12.0827 2.688 12.3867 2.432 12.632C2.18667 12.8773 1.88267 13 1.52 13C1.168 13 0.864 12.8773 0.608 12.632C0.362667 12.3867 0.24 12.0827 0.24 11.72C0.24 11.3573 0.362667 11.0533 0.608 10.808C0.864 10.5627 1.168 10.44 1.52 10.44C1.88267 10.44 2.18667 10.5627 2.432 10.808C2.688 11.0533 2.816 11.3573 2.816 11.72ZM0.528 9.24L0.272 0.983999H2.672L2.416 9.24H0.528Z"
            fill="currentColor"/>
      </svg>

    </div>

    <ul class="form-validation__tooltip" v-if="displayValidationWarning & displayValidationMessages">
      <li v-for="error in recapErrors" :key="error">{{ error }}</li>
    </ul>
  </div>
</template>


<script>
export default {
  inheritAttrs: false,
  props: {
    classes: {
      type: String,
      require: false,
    },
    icon: {
      type: String,
      required: false
    },
  },
  computed: {
    displayValidationWarning: function () {
      return this.inputTouched
          && !this.$store.getters.getMeta(`hideValidationErrors`)
          && !this.$store.getters.getError(`${this.$store.getters.getMeta('modelName')}._is_valid`)
    },
    inputTouched: {
      get () {
        return this.$store.getters.getTouched('modelName')
      },
      set (value) {
        this.$store.commit('setTouched', {
              value: value,
              name: this.$props.name
            }
        )
      }
    },
    recapErrors: function () {
      let recap = this.$store.getters.getError(this.$store.getters.getMeta("modelName"));
      delete recap._is_valid;
      return Object.values(recap);
    }
  },
  data () {
    return {
      disabled: false,
      displayValidationMessages: false,
    }
  },
  methods: {
    submitting () {
      if (this.$store.getters.getError(`${this.$store.getters.getMeta('modelName')}._is_valid`)) {
        setTimeout(() => this.disabled = true);
      }
    },
  }
}
</script>


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Attach a click event listener to the link using capture: true
    // The event  will be dispatched to the registered listener before being dispatched to any EventTarget beneath it in the DOM tree.
    // => This will allow editing the href of the link just before navigating
    this.element.addEventListener("click", this.handleClick.bind(this),{ capture: true } )
  }

  handleClick(event) {
    const link = event.currentTarget
    const currentUrl = window.location.href
    const url = new URL(link.href)

    // Add the `return_to` parameter to the link's href
    url.searchParams.set("return_to", currentUrl)

    link.href = url.toString()
  }
}